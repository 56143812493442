import { React, useState,  useEffect } from "react";
import Table from 'react-bootstrap/Table';



function Playlist(props) {

  
  const [hoverPosition, setHoverPosition] = useState(null);
  const [moveFromPosition,setMoveFromPosition] = useState(null);
  



  const selectItem = (index) => {
    props.selectPlaylistItem(index);
  }

  const changeOrder = (from, to) => {
    if(from==null){
      return;
    }
    const { playlistElements, setPlaylistElements } = props;


    // Create a copy of the playlistElements array
    const updatedPlaylist = [...playlistElements];

    // Remove the element from the 'from' position
    const [movedElement] = updatedPlaylist.splice(from, 1);

    // Insert the element at the 'to' position
    updatedPlaylist.splice(to, 0, movedElement);

    // Update the state with the new playlist order
    setPlaylistElements(updatedPlaylist);

    if(from==props.selecetedIndex){
      props.setSelecetedIndex(to);
    }

  }

  const removeSource = (i) => {
    if (i <= props.selecetedIndex) {
      props.setSelecetedIndex(props.selecetedIndex - 1);
    }
    props.setPlaylistElements(props.playlistElements.filter((item, index) => index !== i));
  }



  return (
    <div className="playlist">
      <h4>Playlist</h4>
      {/* <Table striped hover>
        <thead>

          <tr> */}
      {/* Title */}
      {/* <th></th> */}
      {/* Remove */}
      {/* <th></th>
          </tr>
        </thead>
        <tbody>
          {props.playlistElements.map((item, index) => {return(
            <tr className={selecetedIndex==index && "play-medium"}>
              <td onClick={() => selectItem(index)}>{item.title}</td>
              <td><a href="javascript:;" onClick={() => alert("hello")}><svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg></a></td>
            </tr>)
          })}

        </tbody>
      </Table> */}

      {props.playlistElements.map((item, index) => {

        return (
          <div className={"m-2 row " + (hoverPosition == index && moveFromPosition!=null ? 'select-playlist-position' : (props.selecetedIndex == index ? "play-medium" : ""))} onDrop={(e) => { e.preventDefault(); changeOrder(moveFromPosition, index) }} onDragEnd={(e) => { e.preventDefault(); setHoverPosition(null); setMoveFromPosition(null) }} onDragOver={(e) => { if(moveFromPosition==null) return; e.preventDefault(); setHoverPosition(index); }} >
            <div class="col-1" >
              <a draggable href="javascript:;" onDragStart={(e)=>{setMoveFromPosition(index)}}><svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" /></svg></a>
            </div>
            <div class="col">
              <a href="javascript:;" onClick={() => selectItem(index)}>{item.title}</a>
            </div>
            <div class="col-1">
              <a href="javascript:;" onClick={() => removeSource(index)}><svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg></a>
            </div>
          </div>
        )
      })}

    </div>
  );
}

export default Playlist;