import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as api from '../elements/api/api';
import { Notyf } from 'notyf';



function AddClip(props) {
  const notyf = new Notyf();

  const [url, setUrl] = React.useState("");
  const [type, setType] = React.useState("");

  const storeMailAddress = (sendMail) => {
    if(sendMail ) {
      api.storeMailAddress(props.mail);
    }
    else{
      api.storeMailAddress("");
    }
  };

  const removeChild = (id) => {
    let newRows = props.rows.filter(row => row.id !== id);
    props.setRows(newRows);
  }

  const addJobButtonClicked = () => {
    if (type === "") {
      alert("Please select a format");
      return;
    }

    for (var k in props.rows) {
      if (props.rows[k].url === url) {
        alert("This clip is aleady in your queue!");
        return;
      }
    }
    let id = -props.rows.length - 1;
    let newRow = { id: id, ts_start: Date.now() / 1000, ts_last_action: Date.now() / 1000, status: 0, title: url, url: url, type: type };
    let r = props.rows.concat([newRow]);
    let url2 = url;
    setUrl("");
    props.setRows(r);
    api.addJob(
      url2,
      type,
      (json) => {
        //console.log(url);
        if (json.title != null) {
          storeMailAddress(props.sendMailNotification);
          let newRows = r;
          for (let i = 0; i < newRows.length; i++) {
            if (newRows[i].id === id) {
              newRows[i] = json;
              props.setRows([]);
              props.setRows(newRows);
              notyf.success({
                message: 'Your clip was added successfully to your queue.', duration: 10000, position: {
                  x: 'right', // 'left', 'center' oder 'right'
                  y: 'bottom'    // 'top' oder 'bottom'
                }
              });
              break;
            }

          }

        }
        else {
          removeChild(id);
          // notyf.error('Invalid URL: ' + url2);
          alert("Invalid URL: " + url2);
        }

      })


  };


  return (
    <div>
      <h4>Add Clip</h4>
      <Form.Group className="mb-3 border p-3 rounded">
        <Form.Label>Enter URL of a YouTube clip you want to download</Form.Label>
        <Form.Control value={url} onChange={(e) => { setUrl(e.target.value); }} placeholder="URL" />
        <Form.Label>Select type</Form.Label>
        <Form.Check onChange={() => setType("audio")} type="Radio" name="type" value="audio" label="Audio" />
        <Form.Check onChange={() => setType("video")} type="Radio" name="type" value="video" label="Video" />
      </Form.Group>
      <Button onClick={addJobButtonClicked} variant="primary">Add clip</Button>

    </div>
  );
}

export default AddClip;