import React, { useEffect, useRef } from 'react';
import CenterBox from '../elements/centerBox';

function Player(props) {

    const audioRef = useRef();
    const videoRef = useRef();
    const overlayRef = useRef();

    const closePlayer = () => {
        document.title = "YT-Downloader";
        props.setShowPlayer(false);
    }

    const minimizePlayer = () => {
        // overlayRef.current.style="display:none";
        props.minimizePlayer();
    }

    const maximizePlayer = () => {
        overlayRef.current.style = "";
    }

    useEffect(() => {
        if(props.showWaitingWindow){
            return;
        }
        if (props.source.type == "audio") {
            if (audioRef) {
                audioRef.current.load();
                audioRef.current.play();
            }
        }
        else if (props.source.type == "video") {
            if (videoRef) {
                videoRef.current.load();
                videoRef.current.play();
            }
        }
    }, [props.source.stream_url]);


    // useEffect(() => {
    //     if(!props.minimized){
    //         maximizePlayer();
    //     }
    // },[props.minimized])

    const callbackAfterMediumEnds = () => {
        //console.log(props);
        props.callback();
    };

    return (
        <div class="overlay">
            <div class="kasten" onClick={e => e.preventDefault()}>
                <button class="minimize-btn" onClick={minimizePlayer}>-</button>
                <button class="close-btn" onClick={closePlayer}>×</button>

                <h4>{props.source.title}</h4>
                {
                    props.showWaitingWindow ? 
                    <CenterBox text="Wait until process is finished..." />
                    :
                   ( props.source.type == "audio" ?
                        <audio ref={audioRef} autoPlay onEnded={callbackAfterMediumEnds} controls>
                            <source src={props.source.stream_url}></source>
                        </audio> :
                        <video ref={videoRef} autoPlay onEnded={callbackAfterMediumEnds} controls>
                            <source src={props.source.stream_url}></source>
                        </video>)
                }

            </div>
        </div>
    );

}

export default Player;