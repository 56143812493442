import React, { useState, useEffect, useRef } from 'react';
import useInterval from '../Hooks/useInterval';
import Table from 'react-bootstrap/Table';
import * as api from '../elements/api/api';
import { Notyf } from 'notyf';


function Clips(props) {
  const notyf = new Notyf();
  function formatTime(timestamp) {
    let date = new Date(timestamp * 1000);
    let formatedDate =
      date.getDate() + "." +
      (date.getMonth() + 1) + "." +
      date.getFullYear() + " - " +
      date.getHours() + ":" +
      date.getMinutes() + ":" +
      date.getSeconds()
      ;
    return formatedDate;

  }




  const removeChild = (id) => {
    let newRows = props.rows.filter(row => row.id !== id);
    props.setRows(newRows);
  }

  const addClipToPlaylist = (item) => {
    let index = props.playlistElements.findIndex(element => element.id === item.id);
    if (index !== -1) {
      notyf.error({
        message: '"' + item.title + '"' + " is already in your playlist.", duration: 5000, position: {
          x: 'right', // 'left', 'center' oder 'right'
          y: 'bottom'    // 'top' oder 'bottom'
        }

      });
    }
    else {
      props.setPlaylistElements([...props.playlistElements, item]);
      notyf.success({
        message: '"' + item.title + '"' + " added successfully to your playlist.", duration: 5000, position: {
          x: 'right', // 'left', 'center' oder 'right'
          y: 'bottom'    // 'top' oder 'bottom'
        }
      });
    }
  }

  const removeClip = (id) => {
    let confirmed = window.confirm("Do you realy want remove this job?");
    if (confirmed) {
      api.removeJob(id, () => {
        removeChild(id);
        notyf.success({
          message: 'Clip removed successfully', duration: 5000, position: {
            x: 'right', // 'left', 'center' oder 'right'
            y: 'bottom'    // 'top' oder 'bottom'
          }
        });
      });
    }
  };


  return (
    <div>
      <h4>Clips</h4>
      <Table striped hover>
        <thead>
          <tr>
            <th>Start</th>
            <th>Type</th>
            <th>Title</th>
            <th>Current Status</th>
            {/* Tools */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            props.rows.map(
              (row, i) => {
                return (
                  <tr key={i}>
                    <td>{formatTime(row.ts_start)}</td>
                    <td>{row.type}</td>
                    <td><a target='_blank' href={row.url}>{row.title}</a></td>
                    <td>{api.getStatus(row.status)}</td>
                    <td>
                      <div className='tools'>
                        {/* Play */}
                        {
                          row.status !== api.PENDING &&
                          <a href="javascript:;" onClick={() => props.playMedia(row)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" /></svg></a>
                        }
                        {/* Add to Playlist */}
                        {
                          row.status !== api.PENDING &&
                          <a onClick={() => addClipToPlaylist(row)} href="javascript:;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" /></svg></a>
                        }
                        {/* Download */}
                        {
                          row.status === api.FINISHED &&
                          <a href={row.download_url}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 242.7-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7 288 32zM64 352c-35.3 0-64 28.7-64 64l0 32c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-32c0-35.3-28.7-64-64-64l-101.5 0-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352 64 352zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg></a>
                        }
                        {/* Remove */}
                        {
                          (row.status !== api.IN_PROGRESS && row.status !== api.PENDING) &&
                          <a href="javascript:;" onClick={() => removeClip(row.id)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg></a>
                        }
                      </div>
                    </td>
                  </tr>
                );
              }
            )
          }
        </tbody>
      </Table>
    </div>
  );
}

export default Clips;