import React from 'react';

function CenterBox(props){
    return (
        <div className="center-box">
            {props.text}
        </div>
    )
}

export default CenterBox;