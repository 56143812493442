import { React, useState } from "react";
import * as api from '../elements/api/api';
import * as cookies from '../elements/api/CookieManagement';
import PatternInput from '../elements/PatternInput';
import Form from 'react-bootstrap/Form';


function Notifications(props) {
    const pattern = /^[a-zA-Z0-9\-_\.]+@[a-zA-Z0-9\-_\.]+\.[a-z]{2,}$/; //Pattern to validate mail address
    const [matched, setMatched] = useState(false);


    const storeMailAddress = (sendMail) => {
        if (sendMail) {
            api.storeMailAddress(props.mail);
        }
        else {
            api.storeMailAddress("");
        }
    };

    const mailPatternMatched = (m) => {
        props.setMail(m);
        console.log("matched");
        setMatched(true);
        cookies.setCookie("mail", m, 28);
    };

    const mailPatternNotMatched = () => {
        if (matched)
            storeMailAddress(false);
        setMatched(false);
        props.setSendMailNotification(false);
        cookies.setCookie("mail", "", 28);
    }



    return (
        <div>
            <h4>E-Mail notifications</h4>
            <Form.Group className="mb-3 border p-3 rounded">
                <Form.Label>Enter your email</Form.Label>
                <PatternInput onNoMatch={() => mailPatternNotMatched()} onMatch={(m) => mailPatternMatched(m)} pattern={pattern} value={props.mail} />
                <hr />
                <Form.Check checked={props.sendMailNotification} disabled={!matched} onClick={() => { props.setSendMailNotification(!props.sendMailNotification); storeMailAddress(!props.sendMailNotification) }} type="checkbox" label="Send notification when all jobs are finished" />
                {matched ?
                    <a href="javascript:;" onClick={() => api.sendJobList(props.mail)}>Send Job list</a> :
                    <span style={{ color: 'grey' }}>Send Job List</span> 
                }

            </Form.Group>


        </div>

    );
}

export default Notifications;