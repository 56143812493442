import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';

function PatternInput(props){
    const [value, setValue] = useState(props.value);
    const valueChanged = (value) => {
        setValue(value);
        if(props.pattern.test(value)){
            props.onMatch(value);
        }
        else{
            props.onNoMatch();
        }
    };
    
    useEffect(() => {
        valueChanged(props.value);
    }, []);

    return(
        <Form.Control value={value} onChange={e => valueChanged(e.target.value)} className="w-50" placeholder="" />
    );

    
}

export default PatternInput;