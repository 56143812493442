import React, { useState } from 'react';
import * as api from '../elements/api/api';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';



function Login(props) {
  // const navigate = useNavigate();
  const [confirmationButtonEnabled, setConfirmationButtonEnabled] = useState(true);
  const [remember, setRemember] = useState(false);
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");


  const loginButtonClicked = () => {
    setConfirmationButtonEnabled(false);
    api.login(password, remember, json => {
      if (json.success) {
        props.setLoggedIn(true);
      }
      else {
        alert("Wrong password!");
        setConfirmationButtonEnabled(true);
        setPassword("");
      }
    });

  };

  return (
    <Form.Group className="mb-3 border p-3 rounded">
    <Form.Label>Enter password to access application</Form.Label>
    <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" className="mt-2 w-25" placeholder="" />
    <Form.Check className='mt-2'  onChange={() => setRemember(!remember)} checked={remember} type="checkbox" label="Remember me" />
    <Button className='mt-2' onClick={() => loginButtonClicked()} variant="primary">Login</Button>
</Form.Group>
  );

}

export default Login;